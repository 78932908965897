%control {
  width: 45px;
  height: 45px;

  @include media-l {
    width: 40px;
    height: 40px;
    :global(svg) {
      width: 30px;
    }
  }

  @include media-s {
    width: 30px;
    height: 30px;
    :global(svg) {
      width: 20px;
    }
  }

  @include media-l-landscape {
    width: 40px;
    height: 40px;
    :global(svg) {
      width: 30px;
    }
  }

  @include media-s-landscape {
    width: 30px;
    height: 30px;
    :global(svg) {
      width: 20px;
    }
  }
}

%drawer-actions {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: $z-index-drawer-content + 10;
  background-color: $white;

  padding: 20px $layout-padding-l 17px;

  @media (max-width: $media-l) {
    padding: 20px $layout-padding-l 24px;
    @include elevation(2, up);
  }

  @media (max-width: $media-m) and (orientation: portrait) {
    padding: 0 $layout-padding-l 24px;
    @include elevation(2, up);
  }

  @media (min-width: $media-l) {
    padding: 20px $drawer-padding-left 17px;
  }

  @include media-m-landscape {
    padding: 20px $layout-padding-l 24px;

    @include elevation(2, up);
  }
}

%drawer-title {
  padding: 15px $drawer-padding-left;

  @include media-l {
    //min-height: 100px;
    padding: 15px $layout-padding-l;
    padding-bottom: 10px;
  }
  @include media-l-landscape {
    padding: 0 $layout-padding-l;
    padding-bottom: 10px;
  }
  @media (min-width: $media-m) {
    margin-bottom: 50px;
    padding: 0 $drawer-padding-left;
    padding-bottom: 10px;
  }
  @media (max-width: $media-m) {
    margin-top: 15px;
    margin-bottom: 15px;
    padding-bottom: 10px;
  }
}

%drawer-sections {
  padding: 0 $drawer-padding-left 25px;

  @include media-l {
    padding: 0 $layout-padding-l 25px;
  }
  @include media-l-landscape {
    padding: 0 $layout-padding-l 25px;
  }
}

%drawer-action-container {
  display: flex;
  row-gap: 16px;
  background-color: $white;
  flex-direction: row;

  @media (min-width: $media-l) and (orientation: landscape) {
    flex-direction: column;
    padding: 24px 0;
  }

  @include media-l {
    flex-direction: row;
    column-gap: 8px;

    :global(a) {
      width: 100%;
    }
    :global(a:nth-child(2)), :global(button) {
      min-width: calc(100% - 56px);
    }
  }
  @include media-l-landscape {
    flex-direction: row;
    column-gap: 8px;

    :global(a) {
      width: 100%;
    }
    :global(a:nth-child(2)), :global(button) {
      min-width: calc(100% - 56px);
    }
  }
}

%link {
  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
}

%font {
  font-family: 'Prompt', sans-serif;
  text-transform: unset;
  color: black;
}

%p {
  @extend %font;
  font-size: 1em;
}

%span {
  @extend %font;
  font-size: 1em;
}

%h1 {
  @extend %font;
  font-size: $font-factor-12;
  font-weight: 300;
}

%h2 {
  @extend %font;
  font-size: $font-factor-10;
  margin: 40px 0;

  @include media-l {
    font-size: 21px;
    margin: 0;
  }
}

%h3 {
  @extend %font;
  font-size: $font-factor-8;
}

%h4 {
  @extend %font;
  font-size: $font-factor-6;
}

%h5 {
  @extend %font;
  font-size: $font-factor-4;
}

%h6 {
  @extend %font;
  font-size: $font-factor-2;
}

