@import "../Shared";

.modal {
  position: fixed;
  top: 0;
  right: 0;
  z-index: $z-index-modals;
  overflow: scroll;

  width: 100vw;
  height: 102vh;
  padding: 130px 0;

  background-color: rgba(0, 0, 0, 0.7);

  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  @include media-l {
    height: 100vh;
    width: 100%;

    display: none !important;

    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    padding: 20% 15px;
  }

  @include media-l-landscape {
    padding: 10% 15px;
  }

  &__container {
    position: relative;

    transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
    opacity: 0;
    transform: translateY(40px);

    width: 40%;
    max-width: calc(100% - 40px);
    margin: auto;
    padding: $layout-padding;

    background-color: white;

    @include elevation(2);
    @include media-l {
      opacity: 1;
      transform: translateY(0px);

      max-width: unset;
      width: 100%;
      padding: 40px 30px;
    }

    @include media-l-landscape {
      opacity: 1;
      transform: translateY(0px);

      max-width: unset;
      width: 100%;
      padding: 40px 30px;
    }
  }

  &__cross {
    position: absolute;
    right: -70px;
    top: -60px;

    width: 25px;
    height: 25px;

    cursor: pointer;

    @include media-l {
      right: 0;
      top: -40px;
    }
    @include media-l-landscape {
      right: 0;
      top: -40px;
    }

    & > .line {
      position: absolute;

      transform: rotate(45deg);

      width: 25px;
      height: 3px;
      border-radius: 1px;

      background-color: white;

      &:nth-of-type(2) {
        transform: rotate(-45deg);
      }
    }
  }

  &__title {
    margin: 0 0 30px;
  }

  &.-active {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;

    @include media-l {
      display: block !important;
    }

    .modal__container {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  &.visible-l {
    @include media-l {
      display: block !important;
    }

    .modal {
      &__container {
        @include media-l {
          padding: 0;
        }
      }
    }
  }
}
