.surveyContainer {
  display: flex;
  flex-direction: column;
  margin: 0px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.inputGroup label {
  margin-bottom: 5px;
  font-weight: bold;
}

.formControl {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  &:focus {
    outline: none;
    border-color: #66afe9;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6);
  }
}

.select {
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  &:focus {
    outline: none;
    border-color: #66afe9;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6);
  }
}

.formControl[type="textarea"] {
  height: 100px;
}

.formControl[type="select"] {
  appearance: none;
  &::after {
    content: '▼';
    padding: 0 8px;
  }
}

.checkboxInputGroup {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .checkbox {
    margin-right: 10px;
  }

  .checkboxLabel {
    margin-bottom: 0;
  }
}


.fileInput {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  &:focus {
    outline: none;
    border-color: #66afe9;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6);
  }
}
