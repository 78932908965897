.invoiceItem {
  display: flex;
  flex-direction: column;
  font-family: 'Prompt', sans-serif;
  min-height: 17px;

  .invoiceItemDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .name {
      font-size: 14px;
      color: #333;
      font-family: 'Prompt', sans-serif;
    }

    .heading {
      font-size: 16px;
      font-weight: bold;
      margin-top: 10px;
    }

    // Make text never break line
    .price {
      font-size: 14px;
      color: #666;
      font-family: 'Prompt', sans-serif;

      white-space: nowrap;
    }
  }

  .subItems {

  }
}
