.inputContainer {
  display: flex;
  align-items: center;

  .input {

  }
  .label {

  }
}
