@import "../Shared";

.input {
  @extend %font;
  width: 100%;
  border: 1px solid $medium-grey;
  padding: 10px 15px;

  outline: none;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;

  font-size: 1.2em;

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.error {
    border-color: $color-error;

    &::placeholder {
      color: transparentize($color-error, 0.5);
    }
  }

  & + label {
    margin-top: 15px;
  }
}

.text-area {
  @extend %font;
  width: 100%;
  height: 100px;
  border: 1px solid $medium-grey;
  border-radius: 0;
  padding: 10px 15px;
  font-size: 1.2em;
}
