@import "../Shared";

.title {
  font-size: 1.5em;
}
.prompt {
  font-size: 1.3em;
  padding-bottom: 5px;
}

.modal > div {
  width: 25%;
}

@include media-l {
  .modal > div {
    width: 100%;
  }
}

@include media-l-landscape {
  .modal {
    padding: 5% 15px 0;
  }
  .modal > div {
    width: 100%;
  }
}

.list {
  list-style: none;
  padding: 0;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  margin-bottom: 16px;

  &-item {
    display: flex;

    &-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 5px;
    }
  }
  &-item > img {
    width: 56px;
  }

  &-item > span {
    padding: 16px 0;
    font-size: 1.1em;
    margin-left: 5%;
  }
}

.actions {
  display: flex;
  column-gap: 16px;
}
