@import "../Shared";

.close {
  @extend %control;
  z-index: $z-index-drawer-content;
  position: absolute;
  right: $layout-padding;
  top: $app-top-spacing;
  transition: background-color $fullscreen-transition-length;
  animation-timing-function: $fullscreen-animation-timing-function;

  @include flex-center;

  @media (min-width: $media-l) {
    background-color: $light-grey;
  }

  @media (max-width: $media-l) and (orientation: portrait) {
    background-color: $white;
    right: $layout-padding-l;
    top: $app-top-spacing-l;
  }

  @media (max-width: $media-l) and (orientation: landscape) {
    background-color: $light-grey;
    right: $layout-padding-l;
    top: $app-top-spacing-l;
  }

  cursor: pointer;

  @include media-l {
    position: fixed;
  }

  &:active {
    .line {
      transform: rotate(0);
      &:nth-of-type(2) {
        transform: rotate(0);
      }
    }
  }

  .line {
    @include absolute-center;

    transform: rotate(45deg);
    width: 16px;
    height: 2px;
    margin-left: 14px;
    margin-top: 21px;
    transition: transform 0.1s ease-out;

    background-color: $black;

    @media (max-width: $media-l) {
      width: 15px;
      margin-left: 13px;
      margin-top: 18px;
    }
    @media (max-width: $media-m) {
      width: 12px;
      margin-left: 10px;
      margin-top: 14px;
    }

    &:nth-of-type(2) {
      transition: transform 0.1s ease-out;
      transform: rotate(-45deg);
    }
  }
}

.bg-white {
  background-color: $white;
}
