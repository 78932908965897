@import "../Shared";

.floating-button-section {
  position: absolute;
  left: 0;
  bottom: 30px;
  z-index: $z-index-drawer-content;
  overflow: hidden;

  display: flex;
  justify-content: center;

  width: 100%;

  @media (max-width: $media-s) {
    bottom: unset;
    top: calc(45vh - 40px);
  }

  @media (min-width: $media-s) and (max-width: $media-ml) {
    bottom: unset;
    top: calc(60vh - 40px);
  }

  @media (min-width: $media-ml) {
    bottom: unset;
    top: calc(90vh - 60px);
  }
}
