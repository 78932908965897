@import "../Shared";

.title-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  user-select: none;

  * {
    margin: 0;
  }

  .title {
    font-weight: 500;
    font-size: 1.2em;
    padding: 14px 0;
  }

  & + * {
    margin-top: 10px;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-right: 14px;
    cursor: pointer;
  }
}

.dropdown {
  max-height: 7000px;
  cursor: pointer;

  &.had-interaction {
    transition: max-height 0.5s;
  }

  &.closed {
    max-height: 3.7em;
    overflow: hidden;
  }

  &.has-error {
    border-width: 2px;
    border-color: $color-error;

    & > .title {
      color: $color-error;
    }
  }
}
