@import "../Shared";

.combined-button {
  display: flex;

  .action {
    padding: 8px 14px;

    transition: $fullscreen-transition-length;

    background-color: rgb(238, 238, 238);
    cursor: pointer;

    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;

    &:first-of-type {
      border-bottom-left-radius: 50px;
      border-top-left-radius: 50px;
    }

    &:last-of-type {
      border-bottom-right-radius: 50px;
      border-top-right-radius: 50px;
    }

    &:hover {
      background-color: rgb(217, 217, 217);
    }

    &.active {
      background-color: black;
      color: white;
    }
  }
}
