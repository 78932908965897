@import "../Shared";

.button {
  @extend %font;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  cursor: pointer;
  outline: inherit;
  width: 100%;
  user-select: none;
  text-align: center;

  &.small {
    border-width: 1px;
    padding: 4px 12px;
    font-size: 0.8em;
    min-height: 30px;
  }

  &.medium {
    border-width: 1px;
    padding: 8px 24px;
    font-size: 1em;
    font-weight: 600;
    min-height: 40px;
  }

  &.large {
    border-width: 4px;
    padding: 12px 32px;
    font-size: 1.2em;
    min-height: 50px;
  }

  @include media-l {
    &.small {
      border-width: 1px;
      padding: 4px 12px;
      font-size: 0.8em;
    }

    &.medium {
      border-width: 1px;
      padding: 8px 24px;
      font-size: 1em;
      font-weight: 600;
    }

    &.large {
      border-width: 4px;
      padding: 12px 0;
      font-size: 1.2em;
      line-height: 1.2;
      min-height: 48px;
    }
  }

  @include media-l-landscape {
    &.small {
      border-width: 1px;
      padding: 4px 12px;
      font-size: 0.8em;
    }

    &.medium {
      border-width: 1px;
      padding: 8px 24px;
      font-size: 1em;
      font-weight: 600;
    }

    &.large {
      border-width: 4px;
      padding: 12px 0;
      font-size: 1.2em;
      line-height: 1.2;
      min-height: 48px;
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
