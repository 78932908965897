@import '../../../libs/components/src/v2/Shared/variables.scss';

:global(.pin-me) {
  overflow: hidden;
  position: fixed;
}

.configurator-init {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  transition: background-color 0.5s;
  z-index: $z-index-normal;

  * {
    box-sizing: border-box;
  }

}

.configurator-active {
  overflow: hidden;
}
