@import "../Shared";

.summary {
  .row {
    display: flex;
    justify-content: space-between;

    &:not(.summary__total) {
      .column {
        &:nth-child(1) {
          overflow: hidden;
          flex-grow: 1;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &:nth-child(2) {
          margin-left: 10px;
          color: $dark-grey;
          text-align: right;
        }
      }
    }

    & + .row {
      margin-top: 15px;
    }
  }

  &__title {
    margin: 0;

    & + * {
      margin-top: 15px;
    }
  }

  &__download {
    display: block;

    color: $dark-grey;
    text-decoration: underline;

    & + * {
      margin-top: 10px;
    }
  }

  &__head {

    text-align: center;

    * {
      margin: 0;
    }

    &:not(:empty) {
      & + .summary__content {

        @include media-l {
          margin-top: 0;
        }

        .h2 {
          @include media-l {
            display: none;
          }
        }
      }
    }

    &:empty {
      margin: 0;
    }

    a:first-of-type {
      @include media-l {
        display: none;
      }
    }

    > div {
      @include media-l {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;

    transition: color $fullscreen-transition-length;

    color: $dark-grey;
    text-decoration: underline;
    font-size: $font-size-s;

    svg {
      transition: fill $fullscreen-transition-length;

      margin-right: 10px;
      width: 6px;
      height: auto;

      fill: currentColor;
    }

    & + * {
      margin-top: 30px;
    }

    &:hover {
      color: $black;
    }
  }

  &__divider {
    width: 100%;
    height: 1px;
    margin: 15px 0;

    background-color: $dark-grey;
  }

  &__total {
    .column {
      font-size: $font-size-l;
      color: $black;
    }
  }
  &__vatDetails {
    .column {
      font-size: $font-size-s;
      color: $dark-grey;
    }
  }
}

.extra-information {
  margin: 0;
  display: inline;
  color: #0c3933;
  line-height: 1.7;

  &-icon {
    top: 8px;
    left: 8px;
    font-size: 0.8em !important;
    border-radius: 50%;
    z-index: $z-index-drawer-content;
    justify-content: center;
    align-items: center;
    display: inline-block;
    width: 16px;
    height: 16px;
    text-align: center;
    background-color: #0c3933;
    color: #e6ebea;
    margin-right: 5px;
  }

  &-container {
    background-color: #e6ebea;
    display: block;
    padding: 10px;
    margin-top: 30px;
    line-height: 1.7;
  }
}

.h2 {
  @extend %h2;

  &.less-spacing {
    margin: 0 0 40px 0;
  }
}
