.flex-container {
  display: flex;
}

.flex-child {
  flex: 1;
  //border: 2px solid yellow;
}

.flex-child:first-child {
  margin-right: 10px;
}

.plusMinus {
  vertical-align: center;
  line-height: .5;
}

.button {
  background-color: #f1f1f1!important;
}
