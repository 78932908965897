@import "../Shared";

$padding-right: 4px;

.navigation {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: $z-index-drawer;
  bottom: 0;
  width: calc(100% - 400px);
  padding-bottom: $layout-padding;
  padding-left: $layout-padding;
  padding-right: $layout-padding - $padding-right;
  transition: $fullscreen-transition-length opacity;

  :global(ul) {
    display: flex;
    flex-direction: row;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 8px 0 0 0;
  }

  &.hidden {
    opacity: 0.0;
  }
}

.identifiers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .routeIndex {
    font-size: 1.2em;
    color: #000000;
  }
  .routeName {
    font-size: 1.2em;
    color: #000000;
  }
}

.navItem {
  height: 3px;
  background-color: #dddada;
  flex-grow: 1;
  display: block;
}

.navItem:not(:last-child) {
  margin-right: 8px;
}
