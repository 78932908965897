@import "../Shared";

.list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0 0 10px;
  grid-gap: 8px;

  &::-webkit-scrollbar {
    height: 8px;
    border-radius: 10px;
    background-color: $light-grey;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $medium-grey;

    &:hover {
      background-color: $dark-grey;
    }
  }

  & &-item {
    list-style: none;
    display: inline-block;
    width: 100%;
  }

  & + .description {
    margin-top: 15px;
  }
}

.description {
  display: flex;
  justify-content: space-between;
}

.price {
  color: $dark-grey;

  &.hidden {
    display: none;
  }
}
