// spinner.module.scss
.spinner {
  display: inline-block;
  position: relative;
}

.segment {
  box-sizing: border-box;
  display: block;
  position: absolute;
  border-radius: 50%;
  background: #09f;
  animation: fade 1.2s infinite ease-in-out both;
  transform-origin: center;
}

@keyframes fade {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

/* Optional: If you want to add a slight variation in color or animation timing for visual effect */
@for $i from 1 through 12 {
  .segment-#{$i} {
    animation-delay: -(1.2s - (0.1s * $i));
  }
}
