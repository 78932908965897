@import '../../../../libs/components/src/v2/Shared';

:global(html) {
  padding: env(safe-area-inset);
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

.app {
  font-family: sans-serif;
  height: 100%;
  overflow-x: hidden;
  font-size: 14px;

  @include media-l {
    font-size: 13px;
  }
  @include media-l-landscape {
    font-size: 13px;
  }
}

.layout {
  display: flex;
  flex-direction: row;
  height: 100%;

  @include media-l {
    flex-direction: column;
  }
}

.layout-inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: $light-grey;
  height: 100%;

  @include media-l {
    position: relative;
    display: block;
    flex-grow: unset;
    transition: height $fullscreen-transition-length;
    animation-timing-function: $fullscreen-animation-timing-function;
    height: 40%;
    width: 100%;

    &.fullscreen {
      height: 100%;
    }
  }

  @media (min-width: $media-m) and (orientation: landscape) {
    &.loaded {
      width: calc(100% - #{$drawer-width});
      transition: width $fullscreen-transition-length;
      animation-timing-function: $fullscreen-animation-timing-function;
    }

    &.fullscreen {
      width: 100%;
    }
  }

  @media (min-width: $media-m) and (orientation: portrait) {
    &.loaded {
      width: 100%;
      transition: height $fullscreen-transition-length;
      animation-timing-function: $fullscreen-animation-timing-function;
    }
  }
}

div.help {
  z-index: $z-index-drawer-content;
  bottom: 20px;
  left: 20px;
  position: absolute;
  color: $dark-grey;
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.9em;
  user-select: none;
  transition: opacity $fullscreen-transition-length;
  animation-timing-function: $fullscreen-animation-timing-function;
  opacity: 1;

  @media (min-width: $media-l) and (orientation: landscape) {
    position: absolute;
    top: $layout-padding + 12px;
    height: 20px;
    left: unset;
    right: calc(#{$drawer-width} - 107px);
    display: inline;
  }

  &.hidden {
    opacity: 0;
  }
}


.renderer {
  height: 100vh;
  width: 100vw;
  transition: transform $fullscreen-transition-length;
  animation-timing-function: $fullscreen-animation-timing-function;

  @include media-l {
    transform: translateY(-430px);
    &.fullscreen {
      transform: translateY(-220px);
    }
  }

  @include media-s {
    transform: translateY(-33vh);
    &.fullscreen {
      transform: translateY(-100px);
    }
  }

  @media (min-width: $media-m) and (orientation: portrait) {
    transform: translateY(-330px);
    &.fullscreen {
      transform: translateY(-220px);
    }
  }

  @media (min-width: $media-s) and (orientation: portrait) {
    transform: translateY(-220px);
    &.fullscreen {
      transform: translateY(-100px);
    }
  }

  @media (min-width: $media-m) and (orientation: landscape) {
    transform: translateX(-220px) translateY(0);
    &.fullscreen {
      transform: translateX(0px) translateY(-20px);
    }
  }

  @include media-l-landscape {
    transform: translateX(-220px);
    &.fullscreen {
      transform: translateX(0px);
    }
  }
}

.drawer-toggle {
  position: absolute;
  top: $app-top-spacing;
  right: calc(#{$drawer-width} + 40px);
  transition: right $fullscreen-transition-length;
  animation-timing-function: $fullscreen-animation-timing-function;
  z-index: $z-index-drawer-content + 1;

  &.closed {
    right: 96px;
  }

  @include media-l {
    position: absolute;
    bottom: 20px;
    right: 50px;
    top: calc(100% - 50px);

    &.closed {
      right: 20px;
      top: 20px;
    }
  }

  @include media-l-landscape {
    top: $app-top-spacing-l;
    right: calc(#{$drawer-width} + 60px);
    &.closed {
      right: 72px;
    }
  }
}

.helpTooltip {
  z-index: 10;
}
