@import "../Shared";

.frame-information {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 300px;
  width: 300px;

  &-container {
    border: 1px solid $light-grey;
    padding: 16px;
  }
}
