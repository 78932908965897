@import "../Shared";

.header {
  position: fixed;
  left: 0;
  z-index: $z-index-drawer;
  pointer-events: none;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: $app-top-spacing $layout-padding;
  width: calc(100% - 400px);

  @include media-l {
    position: absolute;

    justify-content: flex-start;

    width: 100%;
    padding: $app-top-spacing-l $layout-padding-l;
  }

  @include media-l-landscape {
    position: absolute;

    justify-content: flex-start;

    width: 100%;
    padding: $app-top-spacing-l $layout-padding-l;
  }

  * {
    pointer-events: auto;
  }

  &.full {
    width: 100%;
  }
}

.logo {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  @extend %control;
  width: 168px;
  background-position: left;
}

.backlink-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: self-start;
  max-width: 40px;
}

.cost-aggregate {
  margin-left: calc((100% - 400px) / 2);

  @media (max-width: $media-l) {
    margin-left: calc((100% - 800px) / 2);
  }

  @media (max-width: $media-m) {
    display: none;
  }

  @include elevation(2);
  padding: 0 20px;
  display: flex;
  height: 45px;
  background-color: $white;
  transition: $fullscreen-transition-length transform;
  animation-timing-function: $fullscreen-animation-timing-function;
  &.hidden {
    transform: translateY(-250px);
  }
}

.title {
  position: absolute;
  top: 25px;
  left: $layout-padding;
  right: 0;

  margin: 0;
  width: fit-content;

  font-size: 24px;
  text-transform: uppercase;
  font-weight: 400;
  color: $black;

  @include media-l {
    position: static;
  }
}
