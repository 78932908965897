.h6, .h5, .h4, .h3, .h2, .h1, .p, .span {
  font-family: "Prompt", sans-serif;
  text-transform: unset;
  color: black;
}

.p, .span {
  font-size: 1em;
}

.h1 {
  font-size: 2.2em;
  font-weight: 300;
}

.h2 {
  font-size: 2em;
  margin: 40px 0;
}
@media (max-width: 1312px) and (orientation: portrait) {
  .h2 {
    font-size: 21px;
    margin: 0;
  }
}

.h3 {
  font-size: 1.8em;
}

.h4 {
  font-size: 1.6em;
}

.h5 {
  font-size: 1.4em;
}

.h6 {
  font-size: 1.2em;
}