.container {
  width: 100%;
  display: flex;
  position: relative;

  .currentFrameDisplay {
    width: 100%;
    display: flex;
    border: none;
    padding: 5px 10px;
    background-color: #FFFFFF;
    cursor: pointer;
    transition: all ease 100ms;
    align-items: center;

    &:hover {
      background-color: #f6f6f6;
    }

    .currentFrameDisplayLeft {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f4f3f2;
      width: 45px;
      height: 35px;
      flex-shrink: 0;

      svg {
        width: 35px;
        height: 25px;
      }
    }
    .currentFrameDisplayCenter {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      margin: 0 10px;

      .price {
        color: #888888;
      }
    }
    .currentFrameDisplayRight {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin-right: 5px;

      svg {
        width: 15px;
        height: 10px;
        fill: #acacac;
      }
    }
  }
  .dropdown {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 5px 25px -7px rgb(0 0 0 / 42%);
    background-color: white;
    flex-direction: column;
    z-index: 1000;
    display: flex;
    border: 1px solid #E1E1E1;

    //transition: opacity ease 600ms, translate3d ease 300ms;
    visibility: hidden;
    //opacity: 0;
    //transform: translate3d(0, 0%, 0);

    &.dropdownOpen {
      visibility: visible;
      //transform: translate3d(0, 100%, 0);
      //opacity: 1;
    }

    .option {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 5px 7px;
      cursor: pointer;
      background-color: white;
      transition: all ease 100ms;
      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: 1px solid #E1E1E1;

      &:last-child {
        border-bottom: unset;
      }

      &:hover:not([disabled]) {
        background-color: #f6f6f6;
      }
      &:disabled {
        cursor: not-allowed;
        background-color: #f3f3f3;
      }

      .left {
        background-color: #f4f3f2;
        width: 45px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        svg {
          width: 35px;
          height: 25px;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin: 0 10px;

        .price {
          color: #888888;
        }
      }
    }
  }
}
