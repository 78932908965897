@import '../../Shared';

.actions {
  @extend %drawer-actions;
}

.costs {
  padding: 20px 0;
}

.actionButtonContainer {
  @extend %drawer-action-container;
}

.disabled {
  cursor: not-allowed;
  pointer-events: unset;
}

.nextRouteErrorTooltip {
  font-size: 16px;
}
