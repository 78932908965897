.container {
  margin-bottom: 10px;

  .required {
    color: red;
  }

  .error {
    color: #E3523E;
    margin-bottom: 5px;

    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.input {
  border: 1px solid #1C1C1C;
  border-radius: 0;
  padding: 7px 15px;
  background-color: #FFFFFF;
  width: 100%;
  margin-bottom: 5px;
  transition: all ease 60ms;
  font-size: 14px;

  &:hover {
    background-color: #f8f8f8;
  }
  &:focus-visible {
    outline: none;
  }
}

.label {
  margin-left: 0!important;
  padding-left: 0;
}
