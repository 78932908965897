@import "../Shared";

.cost-aggregate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  cursor: pointer;
  text-align: center;
  column-gap: 12px;

  svg {
    margin-left: 10px;
  }

  &.-active {
    span {
      @include media-l {
        display: none;
      }
    }

    svg {
      @include media-l {
        position: absolute;
        right: 30px;
        top: 30px;
        z-index: $z-index-drawer-content;
      }
    }
  }
}
