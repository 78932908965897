@import "../Shared";

.autocomplete {
  font-family: 'Prompt', sans-serif;

  &-input {
    border: 1px solid $medium-grey;
    width: 100%;
    padding: 10px 15px;

    margin-bottom: 8px;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    background-color: transparent;
    font-family: 'Prompt', sans-serif;

    input {
      font-family: 'Prompt', sans-serif;
    }



    font-size: 1.2em;

    &[type='number'] {
      -moz-appearance: textfield;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &.error {
      border-color: $color-error;

      &::placeholder {
        color: transparentize($color-error, 0.5);
      }
    }

    & + label {
      margin-top: 15px;
    }
  }

  &-dropdown-container {
    position: absolute;
    z-index: 1;

    width: 100%;
    margin-top: 10px;

    background-color: white;

    @include elevation(2);

    > * {
      padding: 7px 15px;
      border-left: 2px solid $black;
      border-right: 2px solid $black;

      background-color: white;

      &:first-of-type {
        border-top: 2px solid $black;
      }

      &:last-of-type {
        border-bottom: 2px solid $black;
      }
    }
  }
}
