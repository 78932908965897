@import "../Shared";

.cladding-selection-item {
  display: flex;
  align-items: center;
  padding: 5px;
  position: relative;

  @include elevation(1);
}

.active {
  border: 2px solid lighten($green, 20);
}

.description {
  font-weight: 500;
  font-size: 1.1em;
  line-height: 1.2;
}

.price {
  font-weight: 400;
  font-size: 1.1em;
  color: $dark-grey;
}

.information {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 0.8em !important;
  border-radius: 50%;
  background-color: $light-grey;
  z-index: $z-index-drawer-content;
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
