@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin absolute-center {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  margin: auto;
}
@mixin media-l {
  @media (max-width: $media-l) and (orientation: portrait) {
    @content;
  }
}

@mixin media-m {
  @media (max-width: $media-m) and (orientation: portrait) {
    @content;
  }
}

@mixin media-s {
  @media (max-width: $media-s) and (orientation: portrait) {
    @content;
  }
}

@mixin media-l-landscape {
  @media (max-width: $media-l) and (orientation: landscape) {
    @content;
  }
}

@mixin media-m-landscape {
  @media (max-width: $media-m) and (orientation: landscape) {
    @content;
  }
}

@mixin media-s-landscape {
  @media (max-width: $media-s) and (orientation: landscape) {
    @content;
  }
}

@mixin stick-to-bottom-l {
  @include media-l {
    position: fixed;
    left: 0;
    bottom: 0;

    border-top-left-radius: 14px;
    border-top-right-radius: 14px;

    grid-gap: 0;
    width: 100%;
    padding: 15px $layout-padding-l;

    background-color: white;
  }

  .price {
    display: none;

    @include media-l {
      display: block;
    }

    > div {
      @include media-l {
        display: flex;
        justify-content: center;

        padding: 0;
        background-color: transparent;
      }
    }
  }
}

@mixin hide {
  position: absolute;
  pointer-events: none;
  opacity: 0;
}

@mixin elevation($elevation, $direction: down) {
  @if ($elevation == 1) {
    @if ($direction == up) {
      box-shadow: 0 -1px 3px rgba(0,0,0,0.12), 0 -1px 2px rgba(0,0,0,0.24);
    }
    @else {
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    }
  }
  @if ($elevation == 2) {
    @if ($direction == up) {
      box-shadow: 0 -3px 6px rgba(0,0,0,0.16), 0 -3px 6px rgba(0,0,0,0.23);
    }
    @else {
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }
  }
  @if ($elevation == 3) {
    @if ($direction == up) {
      box-shadow: 0 -10px 20px rgba(0,0,0,0.19), 0 -6px 6px rgba(0,0,0,0.23);
    }
    @else {
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }
  }
  @if ($elevation == 4) {
    @if ($direction == up) {
      box-shadow: 0 -14px 28px rgba(0,0,0,0.25), 0 -10px 10px rgba(0,0,0,0.22);
    }
    @else {
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
  }
  @if ($elevation == 5) {
    @if ($direction == up) {
      box-shadow: 0 -19px 38px rgba(0,0,0,0.30), 0 -15px 12px rgba(0,0,0,0.22);
    }
    @else {
      box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    }
  }
}
