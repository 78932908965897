.frameConfigurator {
  display: flex;
  flex-direction: column;
  align-items: center;

  .spareSpace {
    margin: 15px 0 40px 0;
    padding: 4px 0 4px 0;
    width: 100%;
    border: 1px solid #E1E1E1;
    display: flex;
    justify-content: space-between;
    //align-items: jus;
    background: repeating-linear-gradient(
        -45deg,
        #FFFFFF,
        #FFFFFF 20px,
        #f1f1f1 20px,
        #f1f1f1 40px
    );

    .spareSpaceText {
      color: gray;
      font-size: 14px;
      padding-left: 10px;
    }
    .spareSpaceShrinkText{
      text-align: end;
      text-decoration: underline;
      color: black;
      font-size: 14px;
      text-underline: black;
      cursor: pointer;
      padding-right: 10px;
    }
  }

  .warning {
    margin: 15px 0 40px 0;
    padding: 4px 0 4px 0;
    width: 100%;
    border: 1px solid #ffb5b5;
    display: flex;
    justify-content: center;
    align-items: center;
    background: repeating-linear-gradient(
        -45deg,
        #ffe4e4,
        #ffe4e4 20px,
        #ffc9c9 20px,
        #ffc9c9 40px
    );

    .warningText {
      color: #ff4646;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
