@import "../Shared";
.list {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 0;
  margin-top: 0;

  .listItem {
    display: flex;
    align-items: center;
    padding: 5px;
    border: 1px solid #C5C6C5;
    position: relative;
    margin-bottom: 10px;
    cursor: pointer;
    transition: border-color ease 60ms;

    &.listItemActive {
      border-color: black;
    }
    &:hover {
      border-color: #797979;
    }

    .image {
      width: 50px;
      height: 50px;
      object-fit: contain;
      object-position: center;
    }

    .radioButtonContainer {
      min-width: 24px;
      min-height: 24px;
      border: 1px solid #C5C6C5;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 5px;

      .radioButtonActive {
        background-color: white;
        border-radius: 50%;
        width: 12px;
        height: 12px;
      }
    }

    .checkbox {
      margin: 0 5px;
    }

    .information {
      padding-left: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 1.2;
      min-height: 44px;
      font-family: 'Prompt', sans-serif;

      :global(span:nth-child(2)) {
        font-weight: 400;
        font-size: 1.1em;
        color: $dark-grey;
      }

      .name {
        font-family: 'Prompt', sans-serif;
        font-weight: 500;
        font-size: 1.1em;
        line-height: 1.2;
      }

      .price {
        color: $dark-grey;
      }
    }

    .help {
      position: absolute;
      top: 8px;
      right: 8px;
      height: 17px;
      width: 17px;
    }


    .checkboxContainer {
      min-width: 24px;
      min-height: 24px;
      border: 1px solid #C5C6C5;
      border-radius: 4px;       display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 5px;
      cursor: pointer;
      transition: background-color 0.2s ease, border-color 0.2s ease;

      &.checkboxActive {
        background-color: $white;
        border-color: $dark-grey;
      }


      .checkboxCheckmark {
        width: 12px;
        height: 12px;
        background-color: black; // The checkmark color
        border-radius: 2px;
      }
    }
  }
}
