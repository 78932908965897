@import "../Shared";

.form {
  &__field {
    position: relative;

    &.-two-inputs {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      input,
      .select {
        &:nth-child(2) {
          width: 80px;
        }

        &:nth-child(3) {
          width: calc(100% - 90px);
        }

        & + input {
          margin-top: 0;
        }
      }
    }

    & + * {
      margin-left: 15px;
    }
  }

  &__submit {
    margin-top: 20px;
    width: 100%;
    text-align: center;
    padding: 9px;

    background-color: $black;
    cursor: pointer;

    color: white;
  }
}

.title {
  margin-bottom: 20px;
}

.subtitle {
  margin-top: 40px;
  margin-bottom: 10px;
}

.select {
  width: 100%;
  border: 1px solid $medium-grey;
  padding: 10px 15px;

  outline: none;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;

  font-size: 16px;

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.error {
    border-color: $color-error;

    &::placeholder {
      color: transparentize($color-error, 0.5);
    }
  }

  & + label {
    @extend %font;
    margin-top: 15px;
  }
}

label {
  display: flex;
  @extend %font;
  justify-content: space-between;

  width: 100%;
  padding-left: 5px;
  margin-bottom: 5px;
}

.optional {
  color: $dark-grey;
}

.select {
  position: relative;
  z-index: 1;

  padding: 0;

  cursor: pointer;

  &__options {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    overflow-x: hidden;
    overflow-y: scroll;

    opacity: 0;
    pointer-events: none;

    border: 1px solid $medium-grey;
    width: 100%;
    max-height: 100px;
    padding: 5px 0;

    background-color: white;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__option {
    input {
      opacity: 0;
      position: absolute;
      pointer-events: none;
    }

    label {
      margin: 0;
      padding: 3px 15px;

      cursor: pointer;

      &:hover {
        background-color: $light-grey;
      }
    }

    &.-selected {
      @include hide;

      label {
        display: none;
      }
    }
  }

  &__selected {
    padding: 10px 15px;
  }

  &__icon {
    position: absolute;
    right: 0;
    top: 0;

    @include flex-center;

    justify-content: flex-start;

    margin: auto;
    width: 20px;
    height: 100%;

    cursor: pointer;

    svg {
      transform: rotate(-90deg);
      transform-origin: center;
      transition: transform $transition;

      width: 6px;
    }
  }

  &.-active {
    .select {
      &__icon {
        svg {
          transform: rotate(90deg);
        }
      }

      &__options {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

.privacyPolicyLabel {
  margin-bottom: 0;

  u {
    cursor: pointer;
  }
}
