.heading {
  border-bottom: 1px solid #D8D8D8;
  margin: 15px 0;
  padding-bottom: 10px;
}

.summary {
  border-top: 1px solid #D8D8D8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  padding-top: 13px;
}
