.frameDisplay {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .header {
    border: 1px solid #E1E1E1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FBFBFB;

    .left {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .indexControls {
        display: flex;
        flex-direction: column;
        border-right: 1px solid #E1E1E1;
        margin-right: 10px;

        .upButton, .downButton {
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 38px;
          background-color: #FBFBFB;
          transition: all ease 80ms;
          padding: 0;

          &:not(:disabled) {
            cursor: pointer;

            svg {
              fill: #9f9f9f;
            }

            &:hover {
              background-color: #f6f6f6;
            }
          }

          &:disabled {
            cursor: unset;

            svg {
              fill: #dedede;
            }
          }
        }
        .upButton {
          border-bottom: 1px solid #E1E1E1;
        }
      }

      .widthControl {
        margin-right: 10px;

        .widthControlDynamic {
          background-color: #F2F2F2;
          border: 1px solid #F2F2F2;
          width: 85px;
          height: 30px;
          cursor: text;
          outline: none;
          transition: all ease 60ms;
          color: #6a6a6a;

          &:hover {
            border: 1px solid #d6d6d6;
            background-color: #eeeeee;
            color: #000000;
          }
          &:active {
            color: #000000;
          }
        }
      }
    }

    .right {
      .removeButton {
        cursor: pointer;
        border: none;
        background-color: unset;
        width: 30px;
        height: 30px;
        padding: 0;

        svg {
          fill: #afafaf;
        }

        &:hover {
          svg {
            fill: #838383;
          }
        }
      }
    }
  }
  .content {
    border: 1px solid #E1E1E1;
    border-top: none;
  }
}
