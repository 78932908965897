@import "../Shared";

.toggle {
  @extend %control;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $white;
  cursor: pointer;
}
