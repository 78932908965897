@import "../Shared";

.title {
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 100;
  font-size: 1.6em;
  margin-top: 0;
  margin-bottom: $layout-padding;

  @include media-l {
    font-size: 1.2em;
  }
}


.grid {
  display: flex;
  justify-content: center;

  .helpItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 300px;
    color: black;

    :global(svg) {
      width: 64px;
      height: 64px;

      @include media-l {
        width: 32px;
        height: 32px;
      }
    }

    .helpItemTitle {
      margin: 0;
      font-weight: 400;
      font-size: 1em;
    }

    .helpItemLink {
      margin: 0;
      font-weight: 200;
      font-size: 1.2em;
      text-decoration: underline;

      @include media-l {
        font-size: 0.8em;
      }
    }

    @include media-l {
      min-width: 180px;
    }
  }
}
