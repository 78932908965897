@import "../Shared";

.selection-item {
  display: flex;
  align-items: center;
  padding: 5px;
  border: 2px solid white;
  position: relative;

  transition: border-color .2s ease-in-out;
  @include elevation(1);
}

.active {
  border: 2px solid lighten($green, 20);
}

.image {
  width: 50px;
  height: 50px;

  &-container {
    display: flex;
    border: 1px solid $light-grey;
  }
}

.information {
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50px;
  line-height: 1.2;

  :global(span:first-child) {
    font-weight: 500;
    font-size: 1.1em;
    flex-grow: 1;
  }
  :global(span:nth-child(2)) {
    font-weight: 400;
    font-size: 1.1em;
    color: $dark-grey;
  }
}

.checkbox-container {
  width: 28px;
  height: 28px;
  border: 1px solid lightgrey;
  border-radius: 50%;
  padding: 7px;

  &.active {
    background-color: black;
  }
}

.checkbox-active {
  background-color: white;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.description {
  font-weight: 500;
  font-size: 1.1em;
  line-height: 1.2;
}
.price {
  color: $dark-grey;
}


.extra-description {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 0.8em !important;
  border-radius: 50%;
  background-color: $light-grey;
  z-index: $z-index-drawer-content;
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
