@import "../Shared";

.explanation {
  position: fixed;
  left: 0;
  top: 0;
  z-index: $z-index-modals;
  pointer-events: none;

  @include flex-center;

  transition: opacity 250ms ease-in-out;
  opacity: 0;

  height: 100%;
  width: 100%;
  padding-right: $drawer-width;

  background-color: transparentize($black, 0.25);

  @media (max-width: $media-s) {
    padding-right: 0;
    height: 40%;
  }

  @media (min-width: $media-s) and (max-width: $media-m) {
    padding-right: 0;
    height: 50vh;
  }

  @media (min-width: $media-m) and (max-width: $media-l) and (orientation: portrait){
    padding-right: 0;
    height: 40vh;
  }

  @media (min-width: $media-m) and (max-width: $media-l) and (orientation: landscape){
    padding-right: 0;
    height: 100vh;
  }

  &.open {
    pointer-events: auto;
    opacity: 1;
  }

  &__item {
    text-align: center;

    * {
      margin: 0;
      color: white;
    }

    svg {
      margin-bottom: 20px;

      @include media-s {
        height: 30px;
        width: auto;
      }
    }

    .title {
      font-size: 24px;

      @include media-s {
        font-size: 16px;
      }
    }

    .text {
      font-size: 14px;
      margin-top: 10px;

      @include media-s {
        font-size: 12px;
      }
    }

    & + & {
      margin-left: 160px;

      @include media-s {
        margin-left: 40px;
      }
    }
  }
}
