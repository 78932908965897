$font: 'Prompt', sans-serif;

// Z-INDEXES
$z-index-normal: 99999999999999999999; // baseline to start the app in
$z-index-drawer: 1;
$z-index-drawer-content: 2;
$z-index-modals: 5;
$z-index-modal-content: 6;

// GUTTERS AND SPACING
$drawer-padding-left: 32px;
$drawer-padding-left-l: 28px;
$layout-padding: 40px;
$layout-padding-l: 20px;
$drawer-width: 400px;
$app-top-spacing: 40px;
$app-top-spacing-l: 20px;
$drawer-bottom-padding: 75px;
$header-height: 80px;

// TYPOGRAPHY
$font-size-s: 14px;
$font-size-l: 20px;

// RESPONSIVE
$media-l: 1312px;
$media-ml: 900px;
$media-m: 768px;
$media-s: 586px;

//COLORS
$light-grey: #f1f1f1;
$medium-grey: #dbdbdb;
$dark-grey: #afafaf;
$black: black;
$white: white;
$green: #053630;
$color-error: #bb0101;

$font-factor-0: 1em;
$font-factor-1: 1.1em;
$font-factor-2: 1.2em;
$font-factor-3: 1.3em;
$font-factor-4: 1.4em;
$font-factor-5: 1.5em;
$font-factor-6: 1.6em;
$font-factor-7: 1.7em;
$font-factor-8: 1.8em;
$font-factor-9: 1.9em;
$font-factor-10: 2em;
$font-factor-11: 2.1em;
$font-factor-12: 2.2em;

//TRANSITIONS
$transition: 250ms ease-in-out;
$fullscreen-transition-length: 0.5s;
$fullscreen-animation-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);

