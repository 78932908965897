@import "../Shared";

.drawer {
  pointer-events:initial;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  width: $drawer-width;
  height: 100%;

  transform: translateX($drawer-width);
  transition: transform $fullscreen-transition-length, max-height $fullscreen-transition-length;
  animation-timing-function: $fullscreen-animation-timing-function;

  background-color: $white;

  @include media-l {
    position: relative;
    transform: translateY(100%);
    width: 100%;
    max-height: 60%;

    &.open {
      transform: translateY(0px);
    }
  }

  &.open {
    transform: translateX(0px);
  }

  .help {
    text-decoration: underline;
    position: absolute;
    top: $app-top-spacing + 5px;
    left: $layout-padding;
    cursor: pointer;
    color: lighten(black, 70);

    @include media-l {
      display: none;
    }
    @include media-l-landscape {
      display: none;
    }
  }

  .toggle {
    @extend %control;
    position: absolute;
    right: calc(#{$drawer-width} + #{$app-top-spacing});
    top: $app-top-spacing;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: $white;
    cursor: pointer;

    @include media-l {
      top: -($app-top-spacing + 5px);
    }
  }

  .clearfix {
    margin: 120px $drawer-padding-left 0;
    @media (max-width: $media-l) {
      margin: 80px $drawer-padding-left 0;
    }

    @media (max-width: $media-m) and (orientation: portrait) {
      display: none;
    }

    @include media-s-landscape {
      display: none;
    }
    @include media-m-landscape {
      display: none;
    }
  }

  .title {
    font-weight: 300;
    @extend %drawer-title;
  }
  .title-no-margin-bottom {
    font-weight: 300;
    @extend %drawer-title;
    margin-bottom: 0!important;
  }

  .content {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.drawer-toggle {
  right: calc(#{$drawer-width} + #{$app-top-spacing});
  top: $app-top-spacing;
  color: $black;

  @include media-l {
    top: -($app-top-spacing + 5px);
  }
}
