@import "../../Shared";

.form {
  &-field {
    position: relative;

    &.-two-inputs {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      input,
      .select {
        @extend %font;
        &:nth-child(2) {
          width: 80px;
        }

        &:nth-child(3) {
          width: calc(100% - 90px);
        }

        & + input {
          margin-top: 0;
        }
      }
    }

    &-error {
      :global(label) {
        color: red;
      }
      :global(input) {
        border-color: red;
      }
      :global(span) {
        color: red;
      }
    }

    & + * {
      margin-top: 15px;
    }
  }
}

.optional {
  color: $dark-grey;
}
